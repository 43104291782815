import styled from '@emotion/styled';
import { Link } from 'gatsby';

const NavLink = styled(Link)`
  color: #fff;
  display: block;
  font-size: 1.2rem;
  font-weight: ${(props) => props.fontWeight || 'normal'};
  line-height: 1;
  margin: 0 0 1rem 0;
  opacity: 0.5;
  padding: 0.5rem;
  text-decoration: none;
  transition: opacity 0.3s ease-out;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover,
  &.current-page {
    opacity: 1;
  }

  @media (min-width: 550px) {
    font-size: 2rem;
  }

  @media (min-width: 992px) {
    color: #222;
    display: inline-block;
    font-size: 0.75rem;
    margin: 0 1.5rem 0 0;
    padding: 0;
    position: relative;

    &::after {
      border-bottom: 1px solid #222;
      bottom: -3px;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      opacity: 0;
      position: absolute;
      transition: all 0.3s ease-out;
      width: 0;
    }

    &:hover,
    &.current-page {
      &::after {
        opacity: 1;
        width: 100%;
      }
    }
  }
`;

export default NavLink;
