import React from 'react';
import styled from '@emotion/styled';

import NavLink from './nav-link';

const StyledNav = styled('nav')`
  align-items: center;
  background-color: #000;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  margin-top: 0;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  padding: 1rem;
  position: fixed;
  transition: opacity 0.3s ease-out;
  top: ${({ isOpen }) => (isOpen ? 0 : '-100%')};
  width: 100%;
  z-index: ${({ isOpen }) => (isOpen ? 2 : -2)};

  @media (min-width: 992px) {
    padding: 4rem;

    a {
      &:first-of-type {
        display: none;
      }
    }
  }

  @media (min-width: 992px) {
    background-color: transparent;
    display: block;
    height: auto;
    left: auto;
    opacity: 1;
    padding: 0;
    position: relative;
    top: auto;
    width: auto;
    z-index: auto;
  }
`;

const Nav = ({ isOpen }) => {
  const items = [
    {
      id: 4,
      title: 'Home',
      slug: '/',
    },
    {
      id: 3,
      title: 'Skills',
      slug: '/skills',
    },
    {
      id: 1,
      title: 'Blog',
      slug: '/blog',
    },
    {
      id: 2,
      title: 'From LEGOs to Software',
      slug: '/about',
    },
  ];

  return (
    <StyledNav isOpen={isOpen}>
      {items.map((item) => (
        <NavLink
          key={`nav-link-${item.id}`}
          to={item.slug}
          activeClassName="current-page"
        >
          {item.title}
        </NavLink>
      ))}
    </StyledNav>
  );
};

export default Nav;
