import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import HamburgerButton from './hamburger-button';
import Nav from './nav';
import NavLink from './nav-link';

const Logo = styled(NavLink)`
  color: #222;
  font-size: 1rem !important;
  margin: 0;
  opacity: 1;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header
      css={css`
        align-items: center;
        background-color: #fff;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
        left: 0;
        padding: 0.75rem 1rem;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2;

        @media (min-width: 992px) {
          padding: 0.5rem 3rem;
        }
      `}
    >
      <Logo to="/" fontWeight="bold">
        NK
      </Logo>

      <HamburgerButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />

      <Nav isOpen={isOpen} />
    </header>
  );
};

export default Header;
