import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub,
  faLinkedinIn,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

const StyledFooter = styled('footer')`
  background-color: #111;
  color: #ececec;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: 0.75rem;
  padding: 3rem 1rem;

  > * {
    flex: 1;
  }

  @media (min-width: 768px) {
    align-items: center;
    flex-direction: row;
    padding: 3rem 4rem;
  }
`;

const CopyRight = styled('p')`
  order: 2;

  @media (min-width: 768px) {
    order: 0;
  }
`;

const ExternalNav = styled('nav')`
  order: 1;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    justify-self: flex-end;
    margin-bottom: 0;
    order: 0;
    text-align: right;
  }
`;

const NavIcon = styled('a')`
  color: #fff;
  font-size: 1.5rem;
  margin-left: 2rem;
  text-decoration: none;

  &:first-of-type {
    margin-left: 0;
  }
`;

const Footer = () => {
  const date = new Date();

  return (
    <StyledFooter>
      <CopyRight>Norbert Kalácska &copy; {date.getFullYear()}</CopyRight>
      <ExternalNav>
        <NavIcon
          href="https://www.instagram.com/kalacska_norbi/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </NavIcon>
        <NavIcon
          href="https://www.linkedin.com/in/norbertkalacska/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedinIn} />
        </NavIcon>
        <NavIcon
          href="https://github.com/itsnorbertkalacska"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faGithub} />
        </NavIcon>
        <NavIcon
          href="https://twitter.com/NorbertKalacska"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </NavIcon>
      </ExternalNav>
    </StyledFooter>
  );
};

export default Footer;
