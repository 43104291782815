import React from 'react';

import styles from './hamburger-button.module.css';

const HamburgerButton = ({ isOpen, onClick }) => (
  <button
    className={`${styles.hamburgerButton} ${isOpen && styles.isOpen}`}
    onClick={onClick}
  >
    <span />
    <span />
    <span />
  </button>
);

export default HamburgerButton;
