import React from 'react';
import { Global, css } from '@emotion/core';
import { Helmet } from 'react-helmet';

import useSiteMetadata from '../hooks/useSiteMetadata';
import Header from './header';
import Footer from './footer';

const Layout = ({ children, fullWidth = false }) => {
  const { title, description } = useSiteMetadata();

  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          html,
          body {
            margin: 0;
            color: #555;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
              sans-serif;
            font-size: 18px;
            line-height: 1.4;

            /* remove margin for the main div Gatsby mounts into */
            > div {
              margin-top: 0;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: #222;
              font-family: 'Anton', sans-serif;
              line-height: 1.1;

              + * {
                margin-top: 0.5rem;
              }
            }

            strong {
              color: #222;
            }

            li {
              margin-top: 0.25rem;
            }
          }
        `}
      />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          href="https://fonts.googleapis.com/css2?family=Anton&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Header />
      <main
        css={css`
          max-width: 100vw;
          overflow: hidden;
          padding: 46px 1rem 2rem 1rem;
          width: ${fullWidth ? '100%' : '550px'};

          @media (min-width: 992px) {
            padding: 4rem 1rem;
          }
        `}
      >
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
